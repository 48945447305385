// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCionyDPgIgQZko09-almmWQjvj-Jwathk",
    authDomain: "pongfox-387b5.firebaseapp.com",
    databaseURL: "https://pongfox-387b5-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "pongfox-387b5",
    storageBucket: "pongfox-387b5.appspot.com",
    messagingSenderId: "132609117180",
    appId: "1:132609117180:web:xxxxxxxxxxxxxx",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };