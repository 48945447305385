import React from 'react';
import { Box } from '@mui/material';
import { Slider } from '@mui/material';
import { Typography } from '@mui/material';

const DirectionSlider = ({value, handleChange, min, max}) => {
    return(
        <Box className='directionBoxStyle'>
            <h5 className='headerText'>Direction: </h5>
            <Slider
                aria-label='Direction'
                value={value}
                min={min}
                max={max}
                step={1}
                valueLabelDisplay='auto'
                onChange={handleChange}
                sx={{
                    '& .MuiSlider-thumb': {
                        boxShadow: 'none',
                    },
                    paddingLeft: '5px',
                }}
            />
            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                <Typography variant='body2'>Backhand</Typography>
                <Typography variant='body2'>Forehand</Typography>
            </Box>
        </Box>
    );
};

export default DirectionSlider;