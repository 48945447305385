import React, { useState, useEffect } from "react";
import "./board.css";
import {
  getDocs,
  collection,
  query,
  limit,
  startAfter,
  orderBy,
  where,
} from "firebase/firestore";
import { db } from "./FirebaseConfig";
import { fetchRobotConfig } from "./api";

function Board3() {
  const [data, setData] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [heatmapData, setHeatmapData] = useState([]);
  const [locations, setLocations] = useState([]);
  const [configData, setConfigData] = useState({});
  const [boardWidth, setBoardWidth] = useState(70);
  const [drillData, setDrillData] = useState([]);
  const [uniqueStrokes, setUniqueStrokes] = useState([]);
  const [selectedStroke, setSelectedStroke] = useState("");
  const [locationCount, setLocationCount] = useState(0);

  const fetchData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      let q = query(
        collection(db, "drill_library"),
        where("ble", "==", "50:51:A9:8B:73:08")
      );
      const querySnapshot = await getDocs(q);
      const drillData = querySnapshot.docs.map((doc) => ({
        drill: doc.data().drill,
        count: 1,
      }));

      console.log(drillData);

      const newDrills = [];
      const newUniqueStrokes = new Set();

      drillData.forEach((item) => {
        Object.values(item.drill).forEach((drill) => {
          if (typeof drill === "object" && drill !== null) {
            newDrills.push(drill);
            if ("strokeType" in drill) {
              newUniqueStrokes.add(drill.strokeType);
            }
          }
        });
      });

      setDrillData(newDrills);
      setUniqueStrokes(Array.from(newUniqueStrokes));
      updateLocations(newDrills, "");

      //   console.log("Unique strokes:", Array.from(newUniqueStrokes));
      //   console.log("Drill data:", newDrills);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const updateLocations = (drills, strokeType) => {
    setLoading(true);
    const filteredLocations = drills
      .filter((drill) => !strokeType || drill.strokeType === strokeType)
      .map((drill) => {
        let x;
        switch (drill.location) {
          case 'backhand':
            x = 10;
            break;
          case 'middle':
            x = boardWidth / 2;
            break;
          case 'forehand':
            x = boardWidth-10;
            break;
          default:
            x = 0;
        }
        return { x, y: 140 };
      })
    // .map((drill) => ({ x: drill.location - configData.backhand, y: 140 }));
    setLocations(filteredLocations);
    console.log("Locations:", filteredLocations);
    setLocationCount(filteredLocations.length);
    setLoading(false);
  };

  const fetchConfigValues = async () => {
    const apiData = await fetchRobotConfig("50:51:A9:8B:73:08");
    // console.log("Data:", apiData);
    apiData.forEach((item) => {
      const { level, config } = item;
      if (level === "servo_H") {
        // console.log("config", config);
        setConfigData(config);
        // console.log(configData);
      }
    });
  };

  useEffect(() => {
    fetchConfigValues();
  }, []);

  useEffect(() => {
    if (configData.backhand && configData.forehand) {
      const width = configData.forehand - configData.backhand;
      setBoardWidth(width > 0 ? width : 70); // Ensure width is not negative
    }
  }, [configData]);

  useEffect(() => {
    fetchData();
  }, [configData]);

  const handleStrokeChange = (e) => {
    const newSelectedStroke = e.target.value;
    setSelectedStroke(newSelectedStroke);
    updateLocations(drillData, newSelectedStroke);
  };

  return (
    <div className="board-container">
      {loading ? (
        <div className="loading-spinner"></div>
      ) : (
        <>
          <div className="controls">
            {uniqueStrokes.length > 0 && (
              <select
                className="dropmenu"
                value={selectedStroke}
                onChange={handleStrokeChange}
              >
                <option value="">All Strokes</option>
                {uniqueStrokes.map((stroke) => (
                  <option key={stroke} value={stroke}>
                    {stroke}
                  </option>
                ))}
              </select>
            )}
            <p className="location-count">Count: {locationCount}</p>
          </div>
          <div className="board-wrapper">
            <div className="board" style={{ width: `${boardWidth}px` }}>
              {locations.map((point, index) => (
                <div
                  key={index}
                  className="point"
                  style={{ left: `${point.x}px`, top: `${point.y}px` }}
                ></div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Board3;