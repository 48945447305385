import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import PopupComponent from './components/PopupComponent';
import CreateSession from './components/CreateSession';
import CreateDrill from './components/CreateDrill';
import DeviceIdCheck from './components/DeviceIdCheck';
import HeatMap from './components/HeatMap';
import Board from './components/Board';

import History from './components/History';
import Board2 from './components/Board2';
import Board3 from './components/Board3';

function App() {
  return (
    <Router>
    <div className="App">
      <Routes>
        <Route path="/" element={<CreateSession />} />
        <Route path="/device" element={<CreateSession />} />
        <Route path="/createDrill" element={<CreateDrill />} />
        <Route path="/enter-device-id" element={<DeviceIdCheck />} />
        <Route path="/heatmap" element={<HeatMap />} />
        <Route path="/history" element={<History />} />
        <Route path="/board" element={<Board />} />
        <Route path="/board2" element={<Board2 />} />
        <Route path="/board3" element={<Board3 />} />
        {/* Add more routes if needed */}
      </Routes>
    </div>
  </Router>
  );
}

export default App;

