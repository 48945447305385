import axios from 'axios';

export const fetchRobotConfig = async (id) => {
  const response = await axios.post('https://app.pongfox.com/config/robot', {
    id: id
  });
  
  if (!response.data) {
    throw new Error('Invalid response data');
  }

  return response.data;
};

export const submitToApi = async (data) => {
  try {
    console.log(data);
    const response = await axios.post('https://app.pongfox.com/ai/create_drill', data)
    .then((response) => {
      console.log('Success:', response.data);
    })
    .catch((error) => {
      console.error(error);
    })

    console.log(response);
  } catch (error) {
    console.error('Error:', error);
  }
}