import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const OptionButtons = ({ options, selectedOptions, handleOptionChange, maxSelected }) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
      {options.map(option => (
        <Button
          key={option}
          variant={selectedOptions.includes(option) ? 'contained' : 'outlined'}
          onClick={() => handleOptionChange(option)}
          disabled={selectedOptions.length >= maxSelected && !selectedOptions.includes(option)}
        >
          {option}
        </Button>
      ))}
    </Box>
  );
};

export default OptionButtons;
